import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardContent,
  Box,
  Radio,
  RadioGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Select,
  Button,
  InputLabel,
  MenuItem,
  Modal,
} from '@mui/material';
import { v4 } from 'uuid';

const Home = () => {
  const [uuid, setUuid] = useState('')
  const [personType, setPersonType] = useState('Persona Moral')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [motherLastName, setMotherLastName] = useState('')
  const [personalEmail, setPersonalEmail] = useState('')
  const [personalRFC, setPersonalRFC] = useState('')
  const [personalCommercialName, setPersonalCommercialName] = useState('')
  const [personalPhone, setPersonalPhone] = useState('')

  const [commercialName, setCommercialName] = useState('')
  const [commercialRFC, setCommercialRFC] = useState('')
  const [commercialSocialName, setCommercialSocialName] = useState('')
  const [commercialPhone, setCommercialPhone] = useState('')
  const [commercialEmail, setCommercialEmail] = useState('')

  const [howMuch, setHowMuch] = useState('')
  const [source, setSource] = useState('')

  const [privaciAccept, setPrivaciAccept] = useState(false)

  const [firstModal, setFirstModal] = useState(false)
  const [secondModal, setSecondModal] = useState(false)

  const [dlOne, setDlOne] = useState({})
  const [dlTwo, setDlTwo] = useState({})

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const buildFirstTag = () => {
    window.dataLayer = window.dataLayer || []
    const element = {
      'event': 'recibir_codigo_validacion',
      'uuid':uuid,
      'tipoCliente':personType,
      'nombre': firstName,
      'apellidoPaterno':lastName,
      'apellidoMaterno': motherLastName,
      'emailPersonal': personalEmail,
      'rfc': personalRFC,
      'nombreComercial': personalCommercialName,
      'telefonoPersonal': personalPhone,
      'montoPrestamo': howMuch,
      'fuenteInformacion': source,
    }
    window.dataLayer.push(element);
    setDlOne(element)
    setFirstModal(true)
  }

  const buildSecondTag = () => {
    window.dataLayer = window.dataLayer || []
    const element = {
      'event': 'envio_formulario',
      'uuid': uuid,
      'tipoCliente': personType,
      'nombre': firstName,
      'apellidoPaterno': lastName,
      'apellidoMaterno': motherLastName,
      'emailPersonal': personalEmail,
      'telefonoPersonal': personalPhone,
      'rfc': commercialRFC,
      'razonSocial': commercialSocialName,
      'nombreComercial': commercialName,
      'telefonoEmpresa': commercialPhone,
      'emailEmpresa': commercialEmail,
      'montoPrestamo': howMuch,
      'fuenteInformacion': source,
  }
    window.dataLayer.push(element);
    setDlTwo(element)
    setSecondModal(true)
  }

  useEffect(() => {
    setUuid(v4())
  }, [])

  return (<>
    <Container className="header">
      <h1>Crédito de nogocios</h1>
    </Container>

    <Container>
      <h2>¡Haz crecer tu negocio!</h2>
      <p>Con Crédito Negocios Coppel puedes solicitar un crédito de hasta $500,000 MXN.<br/>Compártenos tus datos y recibe atención personalizada en menos de 24 horas.</p>
    </Container>

    <Container style={{ marginBottom: '50px', marginTop: '50px' }}>
      <FormControl>
        <FormLabel>Selecciona qué tipo de empresario eres:*</FormLabel>
        <RadioGroup
          row
          name="person"
          value={personType}
          onChange={(e) => setPersonType(e.currentTarget.value)}
          required
        >
          <FormControlLabel control={<Radio />} value="Persona Moral" label="Persona Moral" />
          <FormControlLabel control={<Radio />} value="Persona Física con Actividad Empresarial" label="Persona Física con Actividad Empresarial" />
        </RadioGroup>
      </FormControl>
    </Container>

    <Container style={{ marginBottom: '50px' }}>
      <h3>Datos personales</h3>
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '1.2em',
        }}
      >
        <TextField required value={firstName} onChange={(e) => setFirstName(e.currentTarget.value) } label="Nombre(s)" />
        <TextField required value={lastName} onChange={(e) => setLastName(e.currentTarget.value) } label="Apellido paterno" />
        <TextField required value={motherLastName} onChange={(e) => setMotherLastName(e.currentTarget.value) } label="Apellido materno" />
        <TextField required value={personalEmail} onChange={(e) => setPersonalEmail(e.currentTarget.value) } label="Correo electrónico" />
        {personType === 'Persona Física con Actividad Empresarial' &&
          <TextField required value={personalRFC} onChange={(e) => setPersonalRFC(e.currentTarget.value) } label="RFC" />}
        {personType === 'Persona Física con Actividad Empresarial' &&
          <TextField required value={personalCommercialName} onChange={(e) => setPersonalCommercialName(e.currentTarget.value) } label="Nombre comercial" />}
        <TextField required value={personalPhone} onChange={(e) => setPersonalPhone(e.currentTarget.value) } label="Celular" />
        <Button variant="outlined" size="large" disableElevation onClick={buildFirstTag} id="send-code">Recibir código</Button>
      </Box>
    </Container>

    {personType === 'Persona Moral' &&
      <Container style={{ marginBottom: '50px' }}>
        <h3>Datos de la empresa</h3>
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '1.2em',
          }}
        >
          <TextField required value={commercialRFC} onChange={(e) => setCommercialRFC(e.currentTarget.value) } label="RFC" />
          <TextField required value={commercialSocialName} onChange={(e) => setCommercialSocialName(e.currentTarget.value) } label="Razón social" />
          <TextField required value={commercialName} onChange={(e) => setCommercialName(e.currentTarget.value) } label="Nombre comercial" />
          <TextField required value={commercialPhone} onChange={(e) => setCommercialPhone(e.currentTarget.value) } label="Teléfono de tu empresa" />
          <TextField required value={commercialEmail} onChange={(e) => setCommercialEmail(e.currentTarget.value) } label="Correo electrónico" />
        </Box>
      </Container>}

    <Container style={{ marginBottom: '50px' }}>
      <h3>Información complementaria</h3>
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '1.2em',
        }}
      >
        <FormControl>
          <InputLabel id="select-1">¿Cuánto dinero necesitas?</InputLabel>
          <Select
            label="¿Cuánto dinero necesitas?"
            labelId="select-1"
            required
            value={howMuch}
            onChange={(e) => setHowMuch(e.target.value)}
          >
            <MenuItem value="Hasta 100 mil MXN">Hasta 100 mil MXN</MenuItem>
            <MenuItem value="De 100 mil a 200 mil MXN">De 100 mil a 200 mil MXN</MenuItem>
            <MenuItem value="De 200 mil a 300 mil MXN">De 200 mil a 300 mil MXN</MenuItem>
            <MenuItem value="Hasta 500 mil MXN">Hasta 500 mil MXN</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="select-2">¿Cómo te enteraste de Crédito Negocios Coppel?</InputLabel>
          <Select
            label="¿Cómo te enteraste de Crédito Negocios Coppel?"
            labelId="select-2"
            required
            value={source}
            onChange={(e) => setSource(e.target.value)}
          >
            <MenuItem value="Medios digitales">Medios digitales</MenuItem>
            <MenuItem value="Tienda Coppel">Tienda Coppel</MenuItem>
            <MenuItem value="Promotor">Promotor</MenuItem>
            <MenuItem value="Invitación Alianzas">Invitación Alianzas</MenuItem>
            <MenuItem value="Emailing">Emailing</MenuItem>
            <MenuItem value="Otro">Otro</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Container>

    <Container style={{ marginBottom: '50px' }}>
      <FormControl>
        <FormControlLabel
          required
          label="He leído y acepto el Aviso de Privacidad"
          control={
            <Checkbox checked={privaciAccept} onChange={(e) => setPrivaciAccept((prev) => !prev)} />
          }
        />
      </FormControl>
      <div>
        <Button variant="contained" size="large" disableElevation onClick={buildSecondTag} id="send-finish">Enviar</Button>
      </div>
    </Container>

    <div style={{height: '200px'}} />

    <Modal
      open={firstModal}
      onClose={() => setFirstModal(false)}
    >
      <Box style={style}>
        <Card style={{height: '100%'}}>
          <CardContent>
            <h3>Se envió el DataLayer de código:</h3>
            <pre
              style={{
                maxWidth: '95%',
                margin: '0 auto',
                backgroundColor: 'rgb(239, 239, 239)',
                border: '1px solid #ccc',
                padding: '24px',
                overflow: 'auto',
              }}
            >
              {JSON.stringify(dlOne, null, '  ')}
            </pre>
          </CardContent>
        </Card>
      </Box>
    </Modal>

    <Modal
      open={secondModal}
      onClose={() => setSecondModal(false)}
    >
      <Box style={style}>
        <Card style={{height: '100%'}}>
          <CardContent>
            <h3>Se envió el DataLayer final:</h3>
            <pre
              style={{
                maxWidth: '95%',
                margin: '0 auto',
                backgroundColor: 'rgb(239, 239, 239)',
                border: '1px solid #ccc',
                padding: '24px',
                overflow: 'auto',
              }}
            >
              {JSON.stringify(dlTwo, null, '  ')}
            </pre>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  </>)
}

export default Home;