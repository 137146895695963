import { RootRouter } from './routes';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
  return (
    <>
      <CssBaseline />
      <div className="App">
        <RootRouter />
      </div>
    </>
  );
}

export default App;
