import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './pages/home';

export const RootRouter = () => {
  return (
      <BrowserRouter>
          <Switch>
              <Route exact path='/' component={Home} />
          </Switch>
      </BrowserRouter>
  )
}
